.main
{
   position: relative;

   overflow: hidden;

   padding: 60px 0 80px;

   color: #fff;
   background: url(../img/main__bg.jpg) no-repeat top center / cover;

   @media (max-width: 750px)
   {
      padding: 15px 0 30px;

      background: url(../img/main__bg.jpg) no-repeat 62% top / cover;

      &::before
      {
         position: absolute;
         top: 0;
         left: 0;

         width: 100%;
         height: 100%;

         content: "";

         background: rgba(0, 0, 0, .5);
      }
   }

   &__inner
   {
      position: relative;
      z-index: 100;

      max-width: 1240px;
      margin: 0 auto;
      padding: 0 20px;

      @media (max-width: 750px)
      {
         padding: 0 15px;
      }
   }

   &__header
   {
      display: flex;
      align-items: center;

      @media (max-width: 750px)
      {
         flex-wrap: wrap;
      }

      &-logo
      {
         display: block;

         width: 270px;
         height: 68px;

         background: url(../img/logo--white.svg) no-repeat center / contain;

         @media (max-width: 1000px)
         {
            width: 200px;
            height: 50px;
         }

         @media (max-width: 750px)
         {
            flex-basis: 150px;
            flex-shrink: 0;

            width: 150px;
            height: 40px;
         }
      }

      &-contacts
      {
         margin-left: auto;

         text-align: right;

         @media (max-width: 750px)
         {
            margin-top: 10px;
            margin-left: 0;

            text-align: center;
         }
      }

      &-phone
      {
         font-size: 1.5625em;
         font-weight: bold;

         text-decoration: none;

         color: inherit;

         @media (max-width: 1000px)
         {
            font-size: 1.25em;
         }

         @media (max-width: 750px)
         {
            font-size: 1.125em;
         }

         &::before
         {
            display: inline-block;

            width: 18px;
            height: 18px;
            margin-right: 5px;

            content: "";

            background: url(../img/icons/icon__phone.svg) no-repeat center / contain;

            @media (max-width: 1000px)
            {
               width: 14px;
               height: 14px;
            }
         }
      }
   }

   &__area
   {
      position: relative;

      max-width: 740px;
      padding-top: 10vh;

      @media (max-width: 750px)
      {
         padding-top: 7vh;
      }

      &-text
      {
         font-size: 3.25em;
         font-weight: bold;
         line-height: 1.25;

         text-shadow: 0 4px 100px rgba(0, 0, 0, .8);

         @media (max-width: 1000px)
         {
            font-size: 2.75em;
         }

         @media (max-width: 750px)
         {
            font-size: 1.5em;

            text-shadow: 0 4px 50px rgba(0, 0, 0, .8);
         }
      }

      &-extra
      {
         font-size: 1.25em;
         font-weight: 500;
         line-height: 1.6;

         padding-top: 5vh;

         text-shadow: 0 4px 60px #000, 0 4px 60px #000;

         @media (max-width: 1000px)
         {
            font-size: 1em;
         }

         @media (max-width: 750px)
         {
            font-size: .75em;

            padding-top: 3vh;

            text-shadow: 0 4px 30px #000, 0 4px 30px #000;
         }
      }
   }

   &__links
   {
      display: flex;
      flex-wrap: wrap;

      margin: -10px;
      padding-top: 40px;

      @media (max-width: 1000px)
      {
         margin: -5px;
         padding-top: 20px;
      }

      &-item
      {
         padding: 10px;

         @media (max-width: 1000px)
         {
            padding: 5px;
         }
      }

      &-link
      {
         display: flex;
         align-items: center;

         font-size: .9375em;
         font-weight: 500;

         padding: 20px;

         transition: background-color .3s ease;
         text-decoration: none;

         color: #fff;
         border-radius: 3px;
         background-color: rgba(0, 0, 0, .2);

         @media (max-width: 1000px)
         {
            font-size: .875em;

            padding: 10px;
         }

         @media (max-width: 750px)
         {
            font-size: .75em;
         }

         &:hover
         {
            background-color: rgba(255, 255, 255, .2);
         }

         &--vk
         {
            &::before
            {
               display: inline-block;

               width: 20px;
               height: 12px;
               margin-right: 8px;

               content: "";

               background: url(../img/icons/vk--white.svg) no-repeat center / contain;
            }
         }

         &--ig
         {
            &::before
            {
               display: inline-block;

               width: 16px;
               height: 16px;
               margin-right: 8px;

               content: "";

               background: url(../img/icons/ig--white.svg) no-repeat center / contain;
            }
         }

         &--cursor
         {
            &::before
            {
               display: inline-block;

               width: 16px;
               height: 16px;
               margin-right: 8px;

               content: "";

               background: url(../img/icons/cursor.svg) no-repeat center / contain;
            }
         }
      }
   }
}

.footer
{
   padding: 70px 0 40px;

   border-top: 1px solid #e5e5e5;

   @media (max-width: 750px)
   {
      padding: 30px 0 20px;
   }

   &__inner
   {
      max-width: 1240px;
      margin: 0 auto;
      padding: 0 20px;

      @media (max-width: 750px)
      {
         padding: 0 15px;
      }
   }

   &__row
   {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 750px)
      {
         flex-direction: column;
         justify-content: flex-start;
      }

      &-logo
      {
         display: block;

         width: 244px;
         height: 62px;

         background: url(../img/logo.svg) no-repeat center / contain;

         @media (max-width: 1000px)
         {
            width: 200px;
            height: 50px;
         }

         @media (max-width: 750px)
         {
            width: 150px;
            height: 40px;
         }
      }

      &-socials
      {
         display: flex;
         align-items: center;
         justify-content: center;

         @media (max-width: 750px)
         {
            margin: 20px 0;
         }
      }

      &-social
      {
         display: block;
         flex-basis: 50px;
         flex-shrink: 0;

         width: 50px;
         height: 50px;
         margin: 0 20px;

         transition: background-color .3s ease;

         border: 1px solid #e5e5e5;
         border-radius: 50%;
         background-color: #fff;
         background-repeat: no-repeat;
         background-position: center;
         background-size: 16px;

         @media (max-width: 1000px)
         {
            flex-basis: 40px;

            width: 40px;
            height: 40px;
            margin: 0 10px;

            background-size: 14px;
         }

         &:hover
         {
            background-color: #f7f7f7;
         }

         &--tg
         {
            background-image: url(../img/icons/tg.svg);
         }

         &--wa
         {
            background-image: url(../img/icons/icon__social--wa.svg);
         }

         &--mg
         {
            background-image: url(../img/icons/icon__social--mg.svg);
         }

         &--vb
         {
            background-image: url(../img/icons/icon__social--vb.svg);
         }

         &--wc
         {
            background-image: url(../img/icons/icon__social--wc.svg);
            background-size: 20px;

            @media (max-width: 1000px)
            {
               background-size: 18px;
            }
         }

         &--sk
         {
            background-image: url(../img/icons/icon__social--sk.svg);
         }
      }

      &-contacts
      {
         text-align: right;

         @media (max-width: 750px)
         {
            text-align: center;
         }
      }

      &-phone
      {
         font-size: 1.5625em;
         font-weight: bold;

         text-decoration: none;

         color: $primaryColor;

         @media (max-width: 1000px)
         {
            font-size: 1.25em;
         }

         @media (max-width: 750px)
         {
            font-size: 1.125em;
         }

         &::before
         {
            display: inline-block;

            width: 18px;
            height: 18px;
            margin-right: 5px;

            content: "";

            background: url(../img/icons/icon__phone--green.svg) no-repeat center / contain;

            @media (max-width: 1000px)
            {
               width: 14px;
               height: 14px;
            }
         }
      }
   }

   &__copyright
   {
      padding-top: 55px;

      text-align: center;

      @media (max-width: 1000px)
      {
         padding-top: 35px;
      }

      @media (max-width: 750px)
      {
         font-size: .625em;

         padding-top: 25px;
      }
   }
}

@font-face
{
   font-family: "Montserrat";
   font-weight: normal;
   font-style: normal;

   src: url("../fonts/Montserrat-Regular.woff2") format("woff2"),
   url("../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face
{
   font-family: "Montserrat";
   font-weight: bold;
   font-style: normal;

   src: url("../fonts/Montserrat-Bold.woff2") format("woff2"),
   url("../fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face
{
   font-family: "Montserrat";
   font-weight: 500;
   font-style: normal;

   src: url("../fonts/Montserrat-Medium.woff2") format("woff2"),
   url("../fonts/Montserrat-Medium.ttf") format("truetype");
}


::-moz-selection
{
   color: #fff;
   background: $primaryColor;
}

::selection
{
   color: #fff;
   background: $primaryColor;
}

body
{
   font-family: "Montserrat";

   color: #000;
   background: #fff;
}

label.error
{
   display: block;

   font-size: .75rem;

   padding-top: 5px;

   color: #c00;
}